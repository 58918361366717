const loadMoreBtn = document.querySelector(".button-load-more");
const projectItems = document.querySelectorAll(".project-gallery_item");

if(loadMoreBtn) {
    if(projectItems.length > 8) {
        let currentItems = 8;
        loadMoreBtn.addEventListener('click', (e) => {
            e.preventDefault();
            for (let i = currentItems; i < currentItems + 8; i++) {
                if (projectItems[i]) {
                    projectItems[i].style.display = "block";
                }
            } 
            currentItems += 8;
            if (currentItems >= projectItems.length) {
                e.target.style.display = "none";
            }
        });
    } else {
        loadMoreBtn.style.display = "none";
    }
}

